import React, { useState } from "react";
import { TextField, Button, Typography, Grid } from "@mui/material";

function CompoundInterestCalculator() {
  const [principal, setPrincipal] = useState("");
  const [rate, setRate] = useState("");
  const [time, setTime] = useState("");
  const [frequency, setFrequency] = useState(1);
  const [result, setResult] = useState(null);

  const calculateCompoundInterest = () => {
    const P = parseFloat(principal);
    const r = parseFloat(rate) / 100;
    const t = parseFloat(time);
    const n = parseInt(frequency, 10);

    if (isNaN(P) || isNaN(r) || isNaN(t) || isNaN(n)) {
      setResult("Please enter valid numbers for all fields.");
      return;
    }

    const amount = P * Math.pow(1 + r / n, n * t);
    const interest = amount - P;

    setResult(`The compound interest is ${interest.toFixed(2)} and the total amount after ${t} years is ${amount.toFixed(2)}.`);
  };

  return (
    <div className="compound-interest-calculator">
      <Typography variant="h4" gutterBottom>
        Compound Interest Calculator
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            label="Principal Amount"
            value={principal}
            onChange={(e) => setPrincipal(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Annual Interest Rate (%)"
            value={rate}
            onChange={(e) => setRate(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Time (years)"
            value={time}
            onChange={(e) => setTime(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Compounding Frequency (per year)"
            value={frequency}
            onChange={(e) => setFrequency(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={calculateCompoundInterest} fullWidth>
            Calculate
          </Button>
        </Grid>
        {result && (
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              {result}
            </Typography>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default CompoundInterestCalculator;
