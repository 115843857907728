import { createClient } from 'contentful';

// Initialize the Contentful client
const client = createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
});

// Function to fetch content based on content type
export const fetchContent = async (contentType) => {
  try {
    // Try to fetch `pageContent` first
    const responsePageContent = await client.getEntries({
      content_type: 'pageContent',  // Use the content model you created in Contentful (e.g., 'pageContent')
      'fields.contentType': contentType, // Fetch by the contentType field you created in Contentful
    });
    console.log('pageContent Response:', responsePageContent);
    // If pageContent is found, return it
    if (responsePageContent.items.length > 0) {
      const content = responsePageContent.items[0].fields;
      return {
        type: 'pageContent',
        body: content.description, // Return the description field from pageContent
      };
    }

    // If no `pageContent` found, try to fetch `ArticleWithRankings`
    const responseArticle = await client.getEntries({
      content_type: 'articleWithRanking',
      'fields.contentType': contentType,
    });
    console.log('articleWithRankings Response:', responseArticle); 

    if (responseArticle.items.length > 0) {
      // If we find matching content for `ArticleWithRankings`
      const content = responseArticle.items[0].fields;
      return {
        type: 'articleWithRanking',
        body: content.articleBody, // Assuming articleBody is the rich text field
        columnHeadings: content.columnHeadings || [], // Fetch dynamic column headings
        rankingData: content.rankingData || [], // Fetch ranking data
      };
    }

    // If no matching content found for either type
    return { body: 'Content not available', columnHeadings: [], rankingData: [] };
  } catch (error) {
    console.error('Error fetching Contentful data:', error);
    return { body: 'Failed to load content', columnHeadings: [], rankingData: [] };
  }
};
