import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Navbar from "./components/Navbar";
import HomePage from "./components/HomePage";
import Invest from "./components/Invest/Invest";
import Save from "./components/Save/Save";
import Grow from "./components/Grow/Grow";
import Protect from "./components/Protect/Protect";
import ArticleContent from './components/ArticleContent';
import CompoundInterestCalculator from "./components/Invest/CompoundInterestCalculator";
import PowerOfCompounding from "./components/Invest/PowerOfCompounding";
import BuyVsRent from "./components/Invest/BuyVsRent";
import LifeInsuranceCalculator from "./components/Protect/LifeInsuranceCalculator";
import AuthPage from "./components/AuthPage";
import Dashboard from "./components/Dashboard";
import Dashboard1 from "./components/Dashboard1";
import TokenVerification from './components/TokenVerification';
import AboutPage from "./components/AboutPage";
import PrivateRoute from "./components/PrivateRoute";
import PollPage from './components/PollPage';
import DataPrivacyPolicy from "./components/DataPrivacyPolicy";
import ContactUs from "./components/ContactUs";
import Footer from "./components/Footer";
import SavingPage from "./components/Save/SavingPage";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import AdminDashboard from './components/AdminDashboard';
import { AuthProvider, useAuth } from './context/AuthContext'; // Import the AuthProvider and useAuth

import "./styles.css";

function App() {
  // Ensure we only call `useAuth` within the AuthProvider context
  const { userInfo } = useAuth() || {}; // Provide a fallback in case useAuth returns undefined
  const isAdmin = userInfo && userInfo.email === "warrbenms@gmail.com"; // Replace with the admin's email

  return (
    <Router>
      <div className="App">
        <Navbar />
        <div className="main-content">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/invest" element={<Invest />} />
            <Route path="/save" element={<Save />} />
            <Route path="/grow" element={<Grow />} />
            <Route path="/polls/:poll_type" element={<PollPage />} />
            <Route path="/protect" element={<Protect />} />
            <Route path="/compound-interest-calculator" element={<CompoundInterestCalculator />} />
            <Route path="/buy-vs-rent" element={<BuyVsRent />} />
            <Route path="/lifeinsurancecalculator" element={<LifeInsuranceCalculator />} />
            <Route path="/power-of-compounding" element={<PowerOfCompounding />} />
            <Route path="/login" element={<AuthPage />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="/verify-token" element={<TokenVerification />} />
            <Route path="/dashboard" element={<PrivateRoute element={<Dashboard />} />} />
            <Route path="/dashboard1" element={<Dashboard1 />} />
            <Route path="/data-privacy-policy" element={<DataPrivacyPolicy />} />
            <Route path="/savingpage" element={<SavingPage />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/:contentType" element={<ArticleContent />} /> {/* Dynamic content route */}

            {/* Admin Route Protection */}
            <Route
              path="/admin-dashboard"
              element={isAdmin ? <AdminDashboard /> : <Navigate to="/login" />}
            />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

function AppWrapper() {
  return (
    <AuthProvider>
      <App />
    </AuthProvider>
  );
}

export default AppWrapper;
