import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Typography,
  Grid,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Paper,
  Button,
  Box,
  Link
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const [targets, setTargets] = useState(null);
  const [expenses, setExpenses] = useState({
    current: {},
    previous1: {},
    previous2: {}
  });
  const [newExpenses, setNewExpenses] = useState({
    eating_out: '',
    entertainment: '',
    travelling: '',
    others: '',
  }); // For storing new expense inputs
  const [isEditing, setIsEditing] = useState(false);
  const [editedTargets, setEditedTargets] = useState(null);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [userInfo, setUserInfo] = useState({ email: '', firstName: '', lastName: '' });
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPasswordForm, setShowPasswordForm] = useState(false); // Control password form visibility
  const [reminderSchedule, setReminderSchedule] = useState("");
  const [remindersEnabled, setRemindersEnabled] = useState(true);
  const navigate = useNavigate();

  const numberFormatter = new Intl.NumberFormat('en-IN'); // For formatting numbers with commas

  const currentMonthYear = new Date().toLocaleString('default', { month: 'long', year: 'numeric' });
  const previousMonthYear1 = new Date(new Date().setMonth(new Date().getMonth() - 1)).toLocaleString('default', { month: 'long', year: 'numeric' });
  const previousMonthYear2 = new Date(new Date().setMonth(new Date().getMonth() - 2)).toLocaleString('default', { month: 'long', year: 'numeric' });

  useEffect(() => {
    // Fetch user data and display dashboard content
    fetchUserInfo();
    fetchTargets();
    fetchExpenses();
    fetchReminderSchedule();
  }, []);

  const fetchUserInfo = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/profile`, {
        withCredentials: true,
      });
      setUserInfo({
        firstName: response.data.first_name || response.data.firstName,
        lastName: response.data.last_name || response.data.lastName,
        email: response.data.email,
      });
    } catch (err) {
      console.error('Error fetching user info:', err);
      setError('Failed to load profile information.');
    }
  };

  const fetchTargets = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/savings`);
      setTargets(response.data);
      setEditedTargets(response.data);
    } catch (err) {
      if (err.response && err.response.status === 404) {
        setError("");
      } else {
        console.error("Error fetching targets:", err);
        setError("Error fetching targets");
      }
    }
  };

  const fetchExpenses = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/expenses/current`);
      const fetchedExpenses = response.data || {
        current: { eating_out: 0, entertainment: 0, travelling: 0, others: 0 },
        previous1: { eating_out: 0, entertainment: 0, travelling: 0, others: 0 },
        previous2: { eating_out: 0, entertainment: 0, travelling: 0, others: 0 }
      };
      setExpenses(fetchedExpenses);
    } catch (err) {
      console.error("Error fetching actual expenses:", err);
      setError("Error fetching actual expenses");
    }
  };

  const fetchReminderSchedule = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/reminders/schedule`);
      setReminderSchedule(response.data.schedule);
      setRemindersEnabled(response.data.enabled);
    } catch (err) {
      console.error('Error fetching reminder schedule:', err);
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditedTargets(targets);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedTargets({ ...editedTargets, [name]: value });
  };

  const handleNewExpenseChange = (e) => {
    const { name, value } = e.target;
    // Ensure the value is numeric, non-negative, and within the limit
    if (!isNaN(value) && Number(value) >= 0 && Number(value) <= 1000000) {
      setNewExpenses({ ...newExpenses, [name]: value });
    }
  };

  const handleSave = async () => {
    try {
      const payload = {
        eating_out: parseFloat(editedTargets.eating_out) || 0,
        entertainment: parseFloat(editedTargets.entertainment) || 0,
        travelling: parseFloat(editedTargets.travelling) || 0,
        others: parseFloat(editedTargets.others) || 0,
      };

      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/savings`, payload);
      setTargets(editedTargets);
      setIsEditing(false);
      alert("Targets updated successfully!");
    } catch (err) {
      console.error("Error saving targets:", err);
      alert("Failed to update targets. Please try again.");
    }
  };

  const handleAddExpense = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/expenses/add`, newExpenses);
      setNewExpenses({
        eating_out: '',
        entertainment: '',
        travelling: '',
        others: '',
      });
      alert("Expense added successfully!");
      // Fetch updated cumulative expenses after adding new one
      const updatedExpenses = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/expenses/current`);
      const fetchedExpenses = updatedExpenses.data || {
        current: { eating_out: 0, entertainment: 0, travelling: 0, others: 0 },
        previous1: { eating_out: 0, entertainment: 0, travelling: 0, others: 0 },
        previous2: { eating_out: 0, entertainment: 0, travelling: 0, others: 0 }
      };
      setExpenses(fetchedExpenses);
    } catch (err) {
      console.error("Error adding new expense:", err);
      alert("Failed to add expense. Please try again.");
    }
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      // Submit the new password to the backend
      const response = await axios.post('/api/auth/reset-password', { password: newPassword });
      setMessage(response.data.message);
      setNewPassword('');
      setConfirmPassword('');

      // Display success message for 2 seconds before logging out
      setTimeout(async () => {
        await axios.post('/api/auth/logout');
        navigate('/login');
      }, 2000);

    } catch (err) {
      setError(err.response?.data?.error || 'Failed to reset password');
    }
  };

  const handleStopReminders = async () => {
    if (window.confirm("Are you sure you want to stop receiving reminders?")) {
      try {
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/reminders/stop`);
        setRemindersEnabled(false);
        alert("Reminders stopped successfully.");
      } catch (err) {
        console.error("Error stopping reminders:", err);
        alert("Failed to stop reminders. Please try again.");
      }
    }
  };

  const handleEnableReminders = async () => {
    if (window.confirm("Are you sure you want to re-enable reminders?")) {
      try {
        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/reminders/enable`);
        setRemindersEnabled(true);
        alert("Reminders re-enabled successfully.");
      } catch (err) {
        console.error("Error re-enabling reminders:", err);
        alert("Failed to re-enable reminders. Please try again.");
      }
    }
  };

  // Ensure expenses.current is defined before using Object.keys
  const currentExpenses = expenses.current || {};
  const previous1Expenses = expenses.previous1 || {};
  const previous2Expenses = expenses.previous2 || {};

  // Calculate totals
  const totalTargets = targets ? Object.values(targets).reduce((acc, value) => acc + parseFloat(value || 0), 0) : 0;
  const totalActuals = Object.keys(currentExpenses).reduce((acc, category) => acc + (parseFloat(currentExpenses[category]) || 0), 0);

  return (
    <Box display="flex" justifyContent="center" alignItems="flex-start" minHeight="100vh" bgcolor="#f5f5f5" py={4}>
      <Grid container spacing={4} justifyContent="center">
        
        {/* Profile Information Section */}
        <Grid item xs={12} md={5}>
          <Card sx={{ boxShadow: 3, marginX: { xs: 2, md: 0 } }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>Profile Information</Typography>
              <Typography variant="body1">Name: {userInfo.firstName} {userInfo.lastName}</Typography>
              <Typography variant="body1">Email: {userInfo.email}</Typography>

              {/* Reset Password Link */}
              <Box mt={2}>
                <Link href="#" onClick={() => setShowPasswordForm(true)} sx={{ color: 'blue' }}>
                  Reset Password
                </Link>
              </Box>

              {/* Reset Password Form */}
              {showPasswordForm && (
                <Box mt={4}>
                  <Typography variant="h6" gutterBottom>Reset Password</Typography>
                  {error && <Typography color="error">{error}</Typography>}
                  {message && <Typography color="primary">{message}</Typography>}
                  <form onSubmit={handlePasswordReset}>
                    <TextField
                      label="New Password"
                      type="password"
                      fullWidth
                      margin="normal"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      required
                    />
                    <TextField
                      label="Confirm New Password"
                      type="password"
                      fullWidth
                      margin="normal"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                    />
                    <Box mt={2}>
                      <Button type="submit" variant="contained" color="primary">Reset Password</Button>
                    </Box>
                  </form>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
        
        {/* Savings Targets and Actual Expenses Section */}
        <Grid item xs={12} md={7}>
          <Card sx={{ boxShadow: 3, marginX: { xs: 2, md: 0 } }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>Your Savings Targets and Actual Expenses</Typography>
              {error && <Typography color="error">{error}</Typography>}
              {targets && Object.keys(targets).length > 0 ? (
                <>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Category</TableCell>
                          <TableCell>Target Amount (INR)</TableCell>
                          <TableCell>{currentMonthYear} (Current Month)</TableCell>
                          <TableCell>{previousMonthYear1} (Previous Month)</TableCell>
                          <TableCell>{previousMonthYear2} (Two Months Ago)</TableCell>
                          <TableCell>New Expense (Form)</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Object.keys(targets).map((category) => (
                          <TableRow key={category}>
                            <TableCell>{category.charAt(0).toUpperCase() + category.slice(1)}</TableCell>
                            <TableCell>
                              {isEditing ? (
                                <TextField
                                  name={category}
                                  value={editedTargets[category]}
                                  onChange={handleChange}
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                  type="number"
                                  inputProps={{
                                    min: 0,
                                    max: 1000000, // Set an upper limit for each expense
                                    step: 0.01, // Optional: for precision with decimals
                                  }}
                                />
                              ) : (
                                numberFormatter.format(parseFloat(targets[category] || 0))
                              )}
                            </TableCell>
                            <TableCell
                              sx={{
                                backgroundColor:
                                  parseFloat(currentExpenses[category]) > parseFloat(targets[category])
                                    ? 'rgba(255, 0, 0, 0.3)' // Light red background if expense exceeds target
                                    : 'transparent',
                              }}
                            >
                              {numberFormatter.format(parseFloat(currentExpenses[category] || 0))}
                            </TableCell>
                            <TableCell>
                              {numberFormatter.format(parseFloat(previous1Expenses[category] || 0))}
                            </TableCell>
                            <TableCell>
                              {numberFormatter.format(parseFloat(previous2Expenses[category] || 0))}
                            </TableCell>
                            <TableCell>
                              <TextField
                                name={category}
                                value={newExpenses[category]}
                                onChange={handleNewExpenseChange}
                                fullWidth
                                variant="outlined"
                                size="small"
                                type="number"
                                inputProps={{
                                  min: 0,
                                  max: 1000000, // Set an upper limit for each expense
                                  step: 0.01, // Optional: for precision with decimals
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                        {/* Total Row */}
                        <TableRow>
                          <TableCell><strong>Total</strong></TableCell>
                          <TableCell>{numberFormatter.format(totalTargets)}</TableCell>
                          <TableCell>{numberFormatter.format(totalActuals)}</TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box mt={2}>
                    <Typography variant="body1">
                      Reminder Schedule: {reminderSchedule} (Currently {remindersEnabled ? "Enabled" : "Disabled"})
                    </Typography>
                    {remindersEnabled ? (
                      <Link href="#" onClick={handleStopReminders} sx={{ mt: 2, display: 'block', color: 'red' }}>
                        Stop receiving reminders
                      </Link>
                    ) : (
                      <Link href="#" onClick={handleEnableReminders} sx={{ mt: 2, display: 'block', color: 'green' }}>
                        Re-enable reminders
                      </Link>
                    )}
                  </Box>
                  <Box mt={2} display="flex" justifyContent="flex-end">
                    {isEditing ? (
                      <>
                        <Button onClick={handleSave} variant="contained" color="primary" sx={{ mr: 2, fontSize: '0.8rem', padding: '6px 12px' }}>
                          Save
                        </Button>
                        <Button onClick={handleCancel} variant="outlined" color="secondary" sx={{ fontSize: '0.8rem', padding: '6px 12px' }}>
                          Cancel
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button onClick={handleEdit} variant="contained" className="custom-button">
                          Edit Targets
                        </Button>
                        <Button onClick={handleAddExpense} variant="contained" className="custom-button">
                          Add Expense
                        </Button>
                      </>
                    )}
                  </Box>
                </>
              ) : (
                <Typography>No targets or expenses found. Please set your savings targets and start tracking expenses.</Typography>
              )}
            </CardContent>
          </Card>
        </Grid>
        
      </Grid>
    </Box>
  );
};

export default Dashboard;
