import React, { useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';

const TokenVerification = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');

    console.log('Token extracted from URL:', token);

    if (token) {
      const verifyToken = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/auth/validate-token?token=${token}`, { withCredentials: true });
          console.log('Token verification response:', response.data);

          if (response.data.success) {
            console.log('Token is verified, navigating to dashboard.');
            navigate('/dashboard1'); // Redirect to the dashboard after successful token verification
// Delay token clearance by 3 seconds (3000 ms)
setTimeout(async () => {
    await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/auth/clear-token`, { userId: response.data.userId });
    console.log('Token cleared after delay');
  }, 3000);

          } else {
            console.log('Token verification failed, navigating to login.');
            navigate('/login');
          }
        } catch (err) {
          console.error('Token verification failed:', err);
          navigate('/login');
        }
      };

      verifyToken();
    } else {
      console.error('No token found in the URL');
      navigate('/login');
    }
  }, [location.search, navigate]);

  return null;
};

export default TokenVerification;
