import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Card, CardContent, CardMedia, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";
import "./SimpleSlider.css"; // Ensure this file exists for custom styles

const SimpleSlider = ({ cardData, embedLinkInCard = false }) => { // Add a prop to control link embedding behavior
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {cardData.map((card, index) => {
        const cardContent = (
          <>
            <CardMedia component="div" className="card-media">
              <img src={card.image} alt={card.title} />
            </CardMedia>
            <CardContent className="card-content">
              <Typography variant="h5" component="div" className="card-title">
                {card.title}
              </Typography>
              <Typography variant="body2" color="text.secondary" className="card-description">
                {card.description}
              </Typography>
            </CardContent>
          </>
        );

        return embedLinkInCard && card.link ? (
          <Link
            to={card.link}
            key={index}
            className="no-underline-card"  // Class to remove underline and manage card layout
          >
            <Card className="slider-card">{cardContent}</Card>
          </Link>
        ) : (
          <Card key={index} className="slider-card">
            {cardContent}
            {card.link && (
              <div className="button-container">
                <Button component={Link} to={card.link} variant="contained" color="primary">
                  Learn More
                </Button>
              </div>
            )}
          </Card>
        );
      })}
    </Slider>
  );
};

export default SimpleSlider;
