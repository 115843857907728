// components/DataPrivacyPolicy.js
import React from "react";

const DataPrivacyPolicy = () => {
  return (
    <div className="policy-container">
      <h1>Data Privacy Policy</h1>
      <p>
        Your privacy is important to us. This Data Privacy Policy explains how
        we collect, use, and protect your personal information when you use our
        app.
      </p>

      <h2>Information We Collect</h2>
      <p>We may collect the following types of personal information:</p>
      <ul>
        <li>
          <strong>Personal Identification Information:</strong> Name, email
          address, phone number, etc.
        </li>
        <li>
          <strong>Demographic Information:</strong> Age, gender, location, etc.
        </li>
        <li>
          <strong>Usage Data:</strong> Information about how you use our app,
          including the pages you visit and the actions you take.
        </li>
        <li>
          <strong>User provided information:</strong> Information that you submit with consent for estimating networth. 
          Please note that this informaiton only appoximately estimates the users networth and is used only for the purpose of estimating 
          relative ranks of user. We use secutiry measures to ensure this information is anonymized. Further, we do not collect financial information such as credit card/debit card numbers/bank accounts numbers etc
        </li>
      </ul>
     
      <h2>User Data Deletion</h2>
      <p>
      If you wish to delete the information linked and/or related to your account
      (this includes any information acquired by your consent from third party login providers such as Gmail, LinkedIn, etc.), 
      please email us at admin@fairranker.com. Please write "Delete User Account" in the Subject. The sender email must correspond to the same account that you want to get deleted.
      </p>
 
      <h2>How We Use Your Information</h2>
      <p>We use your personal information to:</p>
      <ul>
        <li>Provide and improve our services</li>
        <li>Personalize your experience</li>
        <li>
          Communicate with you, including sending updates and notifications
        </li>
        <li>Analyze usage and trends to improve our app</li>
        <li>Ensure security and prevent fraud</li>
      </ul>

      <h2>Data Storage and Security</h2>
      <p>
        We implement a variety of security measures to protect your personal
        information. Your data is stored on secure servers and is only
        accessible by authorized personnel. We use encryption and other security
        technologies to safeguard your data.
      </p>

      <h2>Sharing Your Information</h2>
      <p>
        We do not sell, trade, or otherwise transfer your personal information
        to outside parties without your consent, except for trusted third
        parties who assist us in operating our app, conducting our business, or
        servicing you, as long as those parties agree to keep this information
        confidential.
      </p>
      <h2>Cookies and Tracking Technologies</h2>
    <p>
        Our website employs "cookies" and other tracking mechanisms on certain pages. A "cookie" is a small text file used to gather information about your activity on the site. In some cases, cookies and similar technologies may recall the personal information you previously provided. Most web browsers offer options to manage cookies, allowing you to decide whether to accept them and how to delete them.
    </p>
    <p>
        You can configure most browsers to notify you when a cookie is received or to block cookies entirely. However, please be aware that if you delete or block cookies, you may need to re-enter your original user ID and password to access certain parts of the website.
    </p>
    <p>
        Tracking technologies may collect data such as internet domain and host names, IP addresses, browser software and operating system types, clickstream patterns, and the dates and times you access our site. By using cookies and other tracking technologies, we aim to enhance your experience on our website and improve its functionality. Additionally, we may analyze non-personally identifiable information to identify trends and gather statistics.
    </p>

      <h2>Your Rights</h2>
      <p>You have the right to:</p>
      <ul>
        <li>Access your personal information</li>
        <li>Correct any inaccuracies in your data</li>
        <li>Request the deletion of your personal information</li>
        <li>Object to the processing of your data</li>
        <li>Withdraw your consent at any time</li>
      </ul>

      <h2>Changes to Our Privacy Policy</h2>
      <p>
        We may update this Data Privacy Policy from time to time. We will notify
        you of any changes by posting the new policy on this page. You are
        advised to review this policy periodically for any changes.
      </p>

      <h2>Your Consent</h2>
    <p>
        By continuing to use this website, you agree to the terms outlined in our online Privacy Policy and consent to our processing of your Personal Information for the purposes described above, as well as any additional purposes detailed where we collect Personal Information on the website.
    </p>

    <h3>Liability Limitation</h3>

<p>
  While we take reasonable measures to protect the confidentiality and security of your personal information, no data transmission or storage system can be guaranteed to be 100% secure. Therefore, by using this website and our services, you acknowledge and agree that:
</p>

<ol>
  <li>
    <strong>Limited Liability:</strong> We shall not be held liable for any indirect, incidental, special, consequential, or punitive damages, including but not limited to loss of profits, data, use, goodwill, or other intangible losses, resulting from your use of our services or any unauthorized access to or use of our servers and/or any personal information stored therein.
  </li>
  <li>
    <strong>No Warranties:</strong> We do not warrant or represent that the security measures we employ will prevent all unauthorized access, alteration, or destruction of your data. Your use of our website and services is at your own risk.
  </li>
  <li>
    <strong>Force Majeure:</strong> We shall not be liable for any failure to perform our obligations where such failure results from any cause beyond our reasonable control, including but not limited to natural disasters, war, terrorism, acts of government, or other similar events.
  </li>
  <li>
    <strong>Third-Party Services:</strong> We are not responsible for the practices employed by any websites or services linked to or from our website, including the information or content contained therein. Your browsing and interaction on any third-party website or service, including those that have a link on our website, are subject to that third party’s own rules and policies.
  </li>
</ol>

<p>
  By using our services, you agree that our total liability to you for any claim arising from or related to your use of the website or services shall not exceed the amount you have paid to us, if any, for such use during the 12 months preceding the event giving rise to the claim.
</p>

      <h2>Contact Us</h2>
      <p>
        If you have any questions about this Data Privacy Policy, please contact
        us at:
      </p>
      <p>
        Email: admin@fairranker.com
        <br />
      </p>
    </div>
  );
};

export default DataPrivacyPolicy;
