import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import { styled } from "@mui/system";

const StyledTableHead = styled(TableHead)`
  background-color: #ffb900; // Use the yellow color from the provided image
`;

const StyledTableCell = styled(TableCell)`
  color: white;
  font-weight: bold;
`;

const StyledTableBodyCell = styled(TableCell)`
  color: #333; // Adjust the text color as needed
`;

const TopRankingsTable = ({ data }) => {
  if (!Array.isArray(data)) {
    console.error("Expected data to be an array, but got: ", data);
    return <div>Invalid data format</div>;
  }
  return (
    <TableContainer component={Paper}>
      <Table>
        <StyledTableHead>
          <TableRow>
            <StyledTableCell>Rank</StyledTableCell>
            <StyledTableCell>Age</StyledTableCell>
            <StyledTableCell>Education</StyledTableCell>
            <StyledTableCell>City</StyledTableCell>
            <StyledTableCell>Country</StyledTableCell>
            <StyledTableCell>Net Worth(in Lakhs)</StyledTableCell>
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {data.map((user, index) => (
            <TableRow key={index}>
              <StyledTableBodyCell>{index + 1}</StyledTableBodyCell>
              <StyledTableBodyCell>{user.age}</StyledTableBodyCell>
              <StyledTableBodyCell>{user.education}</StyledTableBodyCell>
              <StyledTableBodyCell>{user.city}</StyledTableBodyCell>
              <StyledTableBodyCell>{user.country}</StyledTableBodyCell>
              <StyledTableBodyCell>
                {Number(user.net_worth).toLocaleString()}
              </StyledTableBodyCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

TopRankingsTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      age: PropTypes.number.isRequired,
      education: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      country: PropTypes.string.isRequired,
      net_worth: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default TopRankingsTable;
