import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Typography, Grid, Box } from "@mui/material";
import "../styles.css";  // Your global styles
import SimpleSlider from "./SimpleSlider";  // Import the SimpleSlider component

function FairRankingsList() {
 
  const [alreadySubmittedMessage, setAlreadySubmittedMessage] = useState("");

  const cardData = [
    // Add your card data here
    {
      title: "Best Cities to Invest in India",
      description: "Explore the best cities for real estate investment in India.",
      link: "/polls/India",
      image: "images/bestcities.jpg"
    },
    {
      title: "Highest Paid CEOs in India",
      description: "How do the top companies in India reward their CEOs?",
      link: "/IndianCEOcompensation",
      image: "/FRlogo.png"
    },
    {
      title: "Life Insurance Cover Poll",
      description: "See how your life Insurance cover compares with others",
      link: "/polls/LifeInsuranceCoverage",
      image: "/FRlogo.png"
    },
   
  ];



  return (
    <Container>
     
      {/* Slider section */}
      {/* Integrate the SimpleSlider with card data */}
 <Box sx={{ mt: 4 }}>

        <SimpleSlider cardData={cardData} embedLinkInCard={true}/>
      </Box>
     
    </Container>
  );
};

export default FairRankingsList;