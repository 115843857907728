// src/components/AuthPage.js
import React, { useState } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "../context/AuthContext"; // Use AuthContext
import "../styles.css";

const AuthPage = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(""); // Add error state
  const navigate = useNavigate();
  const { login } = useAuth(); // Use the login function from AuthContext

  const handleAuth = async (e) => {
    e.preventDefault();
    const endpoint = isLogin ? "/api/auth/login" : "/api/auth/register";
    const data = isLogin ? { email, password } : { firstName, lastName, email, password };
    try {
      const response = await axios.post(endpoint, data);
      if (isLogin) {
        const user = response.data;
        localStorage.setItem("user", JSON.stringify(user));

        // Update AuthContext with the logged-in user
        login(user); // Call login from useAuth to store user in context

        // Redirect based on admin status
        if (user.email === 'warrbenms@gmail.com') {  // Replace with admin email
          navigate("/admin-dashboard"); // Redirect to the admin dashboard
        } else {
          navigate("/dashboard"); // Redirect to the regular dashboard
        }
      } else {
        setIsLogin(true); // Switch to login form after successful registration
      }
    } catch (err) {
      setError(err.response?.data?.error || "An error occurred"); // Set error message
    }
  };

  return (
    <div className="auth-container">
      <h2>{isLogin ? "Login" : "Register"}</h2>
      {error && <p className="error-message">{error}</p>} {/* Display error message */}
      <form className="auth-form" onSubmit={handleAuth}>
        {!isLogin && (
          <>
            <div>
              <label>First Name</label>
              <input
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
            </div>
            <div>
              <label>Last Name</label>
              <input
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </div>
          </>
        )}
        <div>
          <label>Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="auth-buttons">
          <button type="submit" className="auth-button">
            {isLogin ? "Login" : "Register"}
          </button>
          <button
            type="button"
            className="auth-button auth-toggle-button"
            onClick={() => {
              setIsLogin(!isLogin);
              setError(""); // Clear error when switching forms
            }}
          >
            {isLogin ? "Register" : "Login"}
          </button>
        </div>
        {isLogin && (
          <div>
            <Link to="/forgot-password">Forgot Password?</Link>
          </div>
        )}
      </form>
    </div>
  );
};

export default AuthPage;
