import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchContent } from '../utils/contentfulClient';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography, Paper } from '@mui/material'; // Import Material UI components
import './ArticleContent.css'; // Create this CSS file for custom styles
import FairRankingsList from "./FairRankingsList";



const ArticleContent = () => {
  const { contentType } = useParams(); // Get contentType from URL, e.g., 'invest' or 'protect'
  const [content, setContent] = useState(''); // For article body content
  const [columnHeadings, setColumnHeadings] = useState([]); // For table headings
  const [rankingData, setRankingData] = useState([]); // For table data (ranking)
  const [contentTypeFetched, setContentTypeFetched] = useState(''); // Track the type of content fetched
  const [error, setError] = useState(null); // Error handling

  console.log('Content type:', contentType);

  useEffect(() => {
    if (contentType) {
      const loadContent = async () => {
        try {
          const fetchedContent = await fetchContent(contentType); // Fetch content based on the URL

          if (fetchedContent.body) {
            setContent(fetchedContent.body);
          }

          if (fetchedContent.columnHeadings) {
            setColumnHeadings(fetchedContent.columnHeadings);
          }

          if (fetchedContent.rankingData) {
            setRankingData(fetchedContent.rankingData);
          }

          setContentTypeFetched(fetchedContent.type); // Store the type of content fetched
        } catch (err) {
          setError('Failed to load content.');
          console.error('Error fetching content:', err);
        }
      };

      loadContent();
    }
  }, [contentType]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="article-content">
      {/* Render the article body if available */}
      {content && (
        <Paper className="article-body" elevation={3}>
          <Typography variant="body1" component="div">
            {documentToReactComponents(content)}
          </Typography>
        </Paper>
      )}

      {/* Conditionally render the ranking table if content type is ArticleWithRankings and ranking data is available */}
      {contentTypeFetched === 'articleWithRanking' && rankingData.length > 0 && (
        <div className="ranking-section">
          <Typography variant="h5" gutterBottom>
            Ranking
          </Typography>
          <Table className="ranking-table">
            <TableHead>
              <TableRow>
                {columnHeadings.map((heading, index) => (
                  <TableCell key={index} align="center" style={{ fontWeight: 'bold' }}>
                    {heading}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rankingData.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {Object.values(row).map((value, colIndex) => (
                    <TableCell key={colIndex} align="center">
                      {value}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}

      {/* If no rankings are available, show a message or additional content */}
      {contentTypeFetched === 'articleWithRanking' && rankingData.length === 0 && (
        <Typography variant="body2" color="textSecondary">
          No rankings available for this article.
        </Typography>
      )}

<h2>Trending FairRankings</h2>
<FairRankingsList />  {/* Embed the FairRankingsList here */}
    </div>

    
  );
};

export default ArticleContent;
