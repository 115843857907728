import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Typography, Grid, Paper } from "@mui/material";
import { createClient } from "contentful";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import ShareButtons from "../../utils/ShareButtons"; 
import { Helmet } from "react-helmet";
import "../PageStyle.css";

const client = createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
});

function Invest() {
  const [content, setContent] = useState("");

  useEffect(() => {
    client
      .getEntry("2xd8owewspwjHE9zyFJ1B4")
      .then((entry) => {
        setContent(entry.fields.shortDescription);
      })
      .catch((error) => {
        console.error("Error fetching content:", error);
      });
  }, []);

  const baseUrl = window.location.origin;

  return (
    <div className="invest-page">
<Helmet>
        <meta property="og:title" content="Invest" />
        <meta property="og:description" content="Improve your financial health with personalized net worth rankings and insights." />
        <meta property="og:image" content="/powerofcompounding.jpg" />
        <meta property="og:url" content="https://www.rankmyworth.com/Invest" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Invest" />
        <meta name="twitter:description" content="Improve your financial health with personalized net worth rankings and insights." />
        <meta name="twitter:image" content="/powerofcompounding.jpg" />
      </Helmet>

      <Typography variant="h3" gutterBottom>
        Investing
      </Typography>
      <Typography variant="body1" className="invest-content">
        {content ? documentToReactComponents(content) : "Loading content..."}
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Paper className="invest-tool-card" style={{ position: "relative" }}>
            <Typography variant="h5" gutterBottom>
              Compound Interest Calculator
            </Typography>
            <img src="images/compoundint.jpg" alt="Compounding Tool" className="tool-image" />
            <Typography variant="body2" gutterBottom>
              Understand how your investments can grow over time with compound interest.
            </Typography>
            <Link to="/compound-interest-calculator" className="invest-tool-link">
              Try Now
            </Link>
            {/* Share Buttons Component */}
            <ShareButtons link={`${baseUrl}/compound-interest-calculator`} />
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper className="invest-tool-card"style={{ position: "relative" }}>
            <Typography variant="h5" gutterBottom>
              Power of Compounding
            </Typography>
            <img src="/powerofcompounding.jpg" alt="Compounding Tool" className="tool-image" />
            <Typography variant="body2" gutterBottom>
              See how your investments grow over time with the power of compounding.
            </Typography>
            <Link to="/power-of-compounding" className="invest-tool-link">
              Try Now
            </Link>
            {/* Share Buttons Component */}
            <ShareButtons link={`${baseUrl}/power-of-compounding`} />
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper className="invest-tool-card"style={{ position: "relative" }}>
            <Typography variant="h5" gutterBottom>
              Buy vs Rent
            </Typography>
            <img src="/buyvsrent.jpg" alt="Compounding Tool" className="tool-image" />
            <Typography variant="body2" gutterBottom>
              Compare whether buying an asset or renting it is better for you.
            </Typography>
            <Link to="/buy-vs-rent" className="invest-tool-link">
              Try Now
            </Link>
            {/* Share Buttons Component */}
            <ShareButtons link={`${baseUrl}/buy-vs-rent`} />
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper className="invest-tool-card"style={{ position: "relative" }}>
            <Typography variant="h5" gutterBottom>
              Best Cities to Invest in India
            </Typography>
            <img src="images/bestcities.jpg" alt="Compounding Tool" className="tool-image" />
            <Typography variant="body2" gutterBottom>
              Explore the best cities for real estate investment in India.
            </Typography>
            <Link to="/polls/India" className="invest-tool-link">
              Best Cities Ranking
            </Link>
            {/* Share Buttons Component */}
            <ShareButtons link={`${baseUrl}/polls/India`} />
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default Invest;
