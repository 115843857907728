import React from "react";
import { IconButton } from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkIcon from "@mui/icons-material/Link";
import "./ShareButtons.css"; // Create a CSS file to style the buttons

const ShareButtons = ({ link }) => {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(link);
    alert("Link copied to clipboard!");
  };

  return (
    <div className="share-buttons">
      <IconButton onClick={copyToClipboard} size="small">
        <LinkIcon />
      </IconButton>
      <a
        href={`https://api.whatsapp.com/send?text=${encodeURIComponent(link)}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <IconButton size="small">
          <WhatsAppIcon />
        </IconButton>
      </a>
      <a
        href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(link)}&text=Check%20out%20this%20tool!`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <IconButton size="small">
          <TwitterIcon />
        </IconButton>
      </a>
    </div>
  );
};

export default ShareButtons;
