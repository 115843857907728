// components/ContactUs.js
import React from "react";

const ContactUs = () => {
  return (
    <div>
      <h1>Contact Us</h1>
      <p>
        If you have any questions about Rank My Worth, please contact us at:
      </p>
      <p>
        Email: admin@fairranker.com
        <br />
      </p>
    </div>
  );
};

export default ContactUs;
