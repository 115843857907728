import React, { useState } from "react";
import axios from "axios";
import { Box, Typography, Grid, TextField, Button, Card, CardContent } from "@mui/material";

const SavingPage = () => {
  const [categories, setCategories] = useState({
    eating_out: "",
    entertainment: "",
    travelling: "",
    others: "",
  });

  const maxValues = {
    eating_out: 100000,
    entertainment: 100000,
    travelling: 100000,
    others: 100000,
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Ensure the value is a number and within the valid range
    const numericValue = parseFloat(value);
    if (isNaN(numericValue) || numericValue < 0 || numericValue > maxValues[name]) {
      alert(`Please enter a valid number between 0 and ${maxValues[name]} for ${name.replace('_', ' ')}`);
      return;
    }

    setCategories({ ...categories, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/savings`,
        categories
      );
      alert("Targets saved successfully!");
    } catch (err) {
      if (err.response && err.response.status === 400) {
        // If the user has already submitted the targets
        alert(err.response.data.error);
      } else {
        console.error("Error saving targets:", err);
        alert("Please login to use this tool");
      }
    }
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh" bgcolor="#f5f5f5">
      <Card sx={{ width: "100%", maxWidth: "700px", minHeight: "400px", height: "auto", boxShadow: 3, p: 3 }}>
        <CardContent>
          <Typography variant="h4" gutterBottom align="center" sx={{ mb: 8 }}>
            Set Monthly Spending Targets
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Eating Out"
                  name="eating_out"
                  value={categories.eating_out}
                  onChange={handleChange}
                  fullWidth
                  type="number"
                  required
                  variant="outlined"
                  size="small"
                  inputProps={{ min: 0, max: maxValues.eating_out }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Entertainment"
                  name="entertainment"
                  value={categories.entertainment}
                  onChange={handleChange}
                  fullWidth
                  type="number"
                  required
                  variant="outlined"
                  size="small"
                  inputProps={{ min: 0, max: maxValues.entertainment }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Travelling"
                  name="travelling"
                  value={categories.travelling}
                  onChange={handleChange}
                  fullWidth
                  type="number"
                  required
                  variant="outlined"
                  size="small"
                  inputProps={{ min: 0, max: maxValues.travelling }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Others"
                  name="others"
                  value={categories.others}
                  onChange={handleChange}
                  fullWidth
                  type="number"
                  required
                  variant="outlined"
                  size="small"
                  inputProps={{ min: 0, max: maxValues.others }}
                />
              </Grid>
            </Grid>
            <Box display="flex" justifyContent="center" mt={3}>
              <Button type="submit" variant="contained" color="primary" sx={{ px: 4, backgroundColor: '#705501' }}>
                Save Targets
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default SavingPage;
