// Footer.js
import React from "react";
import "../styles.css"; // Ensure the path is correct

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <a href="/data-privacy-policy">Data Privacy Policy</a>
        <a href="/contact-us">Contact Us</a>
      </div>
    </footer>
  );
};

export default Footer;
