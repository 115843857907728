import React from "react";
import { Container, Typography } from "@mui/material";

function AboutPage() {
  return (
    <Container className="section">
      <Typography variant="h4" gutterBottom>
        About Us
      </Typography>
      <Typography variant="body1">
        <p>
          Welcome to Rank My Worth, an innovative app designed to empower
          individuals to take control of their financial health. Our app
          provides users with a clear understanding of their current net worth,
          enabling them to see where they stand financially compared to others.
          By inputting basic information such as age, net worth, education, and
          location, users can receive personalized insights and rankings.
        </p>
        <p>
          Rank My Worth helps users improve their financial health by
          identifying areas where they can make adjustments. Whether it’s
          controlling expenses, increasing savings, or making smarter
          investments, our app offers actionable advice tailored to each user's
          unique situation. With easy-to-understand charts and benchmarks
          against successful financial practices, users can track their progress
          and set realistic financial goals.
        </p>
        <p>
          Our mission is to provide a user-friendly platform that not only
          informs but also motivates and guides users towards financial
          stability and growth. Join us on the journey to better financial
          health and take the first step towards a more secure and prosperous
          future with Rank My Worth.
        </p>
      </Typography>
    </Container>
  );
}

export default AboutPage;
