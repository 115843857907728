import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom'; // To get poll_type from URL
import Comments from './Comments'; // Import the Comments component
import ItemSubmissionForm from './ItemSubmissionForm';
import { Link } from 'react-router-dom'; // Import the Link component
import { generateHashedUniqueId } from '../utils/generateUniqueId'; // Import the unique ID utility
import { createClient } from 'contentful'; // Import Contentful client
import ShareButtons from "./../utils/ShareButtons"; 
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'; // Import rich-text renderer

// Initialize Contentful client using environment variables
const client = createClient({
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
  accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
});

const PollPage = () => {
  const { poll_type } = useParams(); // Get poll_type from URL parameters
  const [items, setItems] = useState([]);
  const [pollTypes, setPollTypes] = useState([]); // State to store poll types
  const [userVotes, setUserVotes] = useState({});
  const [hashedUniqueId, setHashedUniqueId] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [content, setContent] = useState(''); // State to store Contentful content
  const baseUrl = window.location.origin;

  // Fetch content from Contentful based on the poll_type
  useEffect(() => {
    const fetchContent = async () => {
      try {
        const response = await client.getEntries({
          content_type: 'pollContent', // The content type you've created
          'fields.pollType': poll_type, // Fetch content by pollType (Short Text)
        });
        console.log('Contentful response:', response); // Log the entire response to see what is returned
        if (response.items.length > 0) {
          console.log('Fetched description:', response.items[0].fields.description); // Log the fetched description
          setContent(response.items[0].fields.description); // Set the rich text description
        } else {
          setContent('No content available for this poll type.');
        }
      } catch (error) {
        console.error('Error fetching Contentful data:', error);
        setContent('Failed to load content.');
      }
    };
    fetchContent();
  }, [poll_type]);

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/vote/items`, {
          params: { poll_type }, // Pass poll_type as a query parameter
        });
        if (Array.isArray(response.data)) {
          console.log('Poll items:', response.data); // Log the fetched data
          setItems(response.data); // Set items if response is an array
        } else {
          setError('Unexpected data format received.');
        }
      } catch (err) {
        console.error('Error fetching poll items:', err);
        setError('Failed to fetch poll items');
      } finally {
        setLoading(false); // Set loading to false after fetch
      }
    };

    const getUserId = async () => {
      const uniqueId = await generateHashedUniqueId(); // Get the unique ID for the user
      setHashedUniqueId(uniqueId);
    };

    fetchItems();
    getUserId();
  }, [poll_type]); // Refetch items when poll_type changes

  useEffect(() => {
    const fetchPollTypes = async () => {
      try {
        const pollTypesResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/vote/poll_types`);
        if (Array.isArray(pollTypesResponse.data)) {
          setPollTypes(pollTypesResponse.data);
        } else {
          setError('Unexpected data format received for poll types.');
        }
      } catch (err) {
        console.error('Error fetching poll types:', err);
        setError('Failed to fetch poll types');
      }
    };

    fetchPollTypes();
  }, []);

  const handleVote = async (itemId, voteType) => {
    // If the user clicks again on the same voted button, they want to unvote
    if (userVotes[itemId] === voteType) {
      handleUnvote(itemId);
      return;
    }

    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/vote/vote`, {
        itemId,
        voteType,
        uniqueId: hashedUniqueId, // Send the unique ID with the vote request
      });

      setUserVotes({ ...userVotes, [itemId]: voteType });
      setItems(
        items.map((item) =>
          item.id === itemId
            ? { ...item, likes: voteType === 'like' ? item.likes + 1 : item.likes, dislikes: voteType === 'dislike' ? item.dislikes + 1 : item.dislikes }
            : item
        )
      );
    } catch (err) {
      if (err.response && err.response.status === 400) {
        alert(err.response.data.message || 'You have already voted for this item.');
      } else {
        console.error('Error voting:', err);
      }
    }
  };

  const handleUnvote = async (itemId) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/vote/unvote`, {
        itemId,
        uniqueId: hashedUniqueId, // Send the unique ID to remove the vote
      });

      setItems(
        items.map((item) =>
          item.id === itemId
            ? {
                ...item,
                likes: userVotes[itemId] === 'like' ? item.likes - 1 : item.likes,
                dislikes: userVotes[itemId] === 'dislike' ? item.dislikes - 1 : item.dislikes,
              }
            : item
        )
      );
      const updatedVotes = { ...userVotes };
      delete updatedVotes[itemId];
      setUserVotes(updatedVotes);
    } catch (err) {
      console.error('Error unvoting:', err);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    
    <div className="content-list-page">
      <ShareButtons link={`${baseUrl}/polls/${poll_type}`} />
      {/* Render the Contentful content based on poll_type */}
      <div className="poll-content">
        {content && typeof content === 'object' ? documentToReactComponents(content) : content}
      </div>

      <ul id="content-list">
        {items.map((item, index) => (
          <li key={item.id} className="content-item">
            <div className="content-rank">{index + 1}</div>
            {item.imageurl && (
              <img className="content-image" src={item.imageurl} alt={item.name} />
            )}
            <div className="content-info">
              {/* Conditionally render the Link if the poll_type matches the item name */}
              {pollTypes.includes(item.name) ? (
                <Link to={`/polls/${item.name}`}>
                  <h2>{item.name}</h2>
                </Link>
              ) : (
                <h2>{item.name}</h2> // Render name without link if no matching poll_type
              )}
              <p>{item.description}</p>
            </div>
            <div className="content-actions">
              {/* Highlight the button if the user has voted for this action */}
              <button
                className={`content-vote-up ${userVotes[item.id] === 'like' ? 'voted' : ''}`}
                onClick={() => handleVote(item.id, 'like')}
              >
                <i className="fas fa-thumbs-up"></i>
                <span className="votes-count">{item.likes}</span>
              </button>
 {/* Conditionally render thumbs-down button only if poll_type is not LifeInsuranceCoverage */}
 {poll_type !== 'LifeInsuranceCoverage' && (
                <button
                  className={`content-vote-down ${userVotes[item.id] === 'dislike' ? 'voted' : ''}`}
                  onClick={() => handleVote(item.id, 'dislike')}
                >
                  <i className="fas fa-thumbs-down"></i>
                  <span className="votes-count">{item.dislikes}</span>
                </button>
              )}
            </div>
          </li>
        ))}
      </ul>

      {/* Add the item submission form below the rankings */}
      <ItemSubmissionForm pollType={poll_type} />

      {/* Include the Comments section for the current poll_type */}
      <Comments pollType={poll_type} />
    </div>
  );
};

export default PollPage;
