import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState(null); // Store authenticated user info
  const [loading, setLoading] = useState(true); // Loading state to prevent premature access

  // Fetch user info after login (if needed)
  const login = (userInfo) => {
    console.log('Setting userInfo:', userInfo.user); // Debugging info
    setUserInfo(userInfo.user); // Only set the 'user' part
    setLoading(false); // Set loading to false after login
  };

  const logout = () => {
    setUserInfo(null); // Clear user info on logout
  };

  useEffect(() => {
    // Example: Simulate an existing user session check
    const storedUser = JSON.parse(localStorage.getItem("user")); // Simulate a session
    if (storedUser) {
      setUserInfo(storedUser.user);
    }
    setLoading(false); // Set loading to false after session check
  }, []);

  return (
    <AuthContext.Provider value={{ userInfo, loading, login, logout }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext); // Custom hook for accessing auth context
