import React, { useState, useEffect } from "react";
import { TextField, Button, Typography, Grid } from "@mui/material";
import { Line } from "react-chartjs-2";
import 'chart.js/auto';
import { createClient } from "contentful";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'; // Import the rich text renderer

function PowerOfCompounding() {
  // Set default values for the input fields
  const [initialInvestment, setInitialInvestment] = useState("100000");
  const [annualContribution, setAnnualContribution] = useState("100000");
  const [rate, setRate] = useState("15");
  const [years, setYears] = useState("15");
  const [chartData, setChartData] = useState(null);

  const inputLimits = {
    initialInvestment: 100000,
    annualContribution: 50000,
    rate: 20,
    years: 30
  };

 // Initialize Contentful client using environment variables
  const client = createClient({
    space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
    accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
  });

  const [results, setResults] = useState(null);

  const [content, setContent] = useState("");
  useEffect(() => {
    // Replace '<your-contentful-entry-id>' with the actual entry ID from Contentful
    client
      .getEntry('7hPprsjhUeSQGNPM18DTAx')
      .then((entry) => {
        console.log("Fetched entry:", entry); // Log the entire entry object to see all fields
        setContent(entry.fields.shortDescription);
      })
      .catch((error) => {
        console.error("Error fetching content:", error);
      });
  }, []);

  const handleInputChange = (setter, limit) => (e) => {
    const value = e.target.value;
    if (value === "" || parseFloat(value) <= limit) {
      setter(value);
    }
  };

  const calculateCompounding = () => {
    const P = parseFloat(initialInvestment);
    const C = parseFloat(annualContribution);
    const r = parseFloat(rate) / 100;
    const t = parseInt(years, 10);

    if (isNaN(P) || isNaN(C) || isNaN(r) || isNaN(t)) {
      return;
    }

    let compoundedDataPoints = [];
    let nonCompoundedDataPoints = [];
    let totalCompounded = P;
    let totalNonCompounded = P;
    let totalInterestEarned = 0;

    for (let i = 1; i <= t; i++) {
      // Compounded scenario
      totalCompounded = (totalCompounded + C) * (1 + r);
      compoundedDataPoints.push(totalCompounded.toFixed(2));

      // Non-compounded scenario
      const interest = (P + C * i) * r;
      totalInterestEarned += interest;
      totalNonCompounded = (P + C * i) + totalInterestEarned;
      nonCompoundedDataPoints.push(totalNonCompounded.toFixed(2));
    }

    const labels = Array.from({ length: t }, (_, i) => `Year ${i + 1}`);

    setChartData({
      labels: labels,
      datasets: [
        {
          label: "Compounded Investment Value",
          data: compoundedDataPoints,
          fill: false,
          borderColor: "#007bff",
          tension: 0.1
        },
        {
          label: "Non-Compounded Investment Value (Interest Withdrawn)",
          data: nonCompoundedDataPoints,
          fill: false,
          borderColor: "#ff6384",
          tension: 0.1
        }
      ]
    });
  };



  return (
    <div className="power-of-compounding">
      <Typography variant="h4" gutterBottom>
        Power of Compounding
      </Typography>

      <Typography variant="body1" className="invest-content">
        {content ? documentToReactComponents(content) : "Loading content..."}
      </Typography>

      <Typography variant="h4" gutterBottom>
        Power of Compounding Calculator
      </Typography>
      
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            label="Initial Investment (₹,, max -100000)"
            value={initialInvestment}
            onChange={handleInputChange(setInitialInvestment, inputLimits.initialInvestment)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Annual Contribution (₹ , max -100000)"
            value={annualContribution}
            onChange={handleInputChange(setAnnualContribution, inputLimits.annualContribution)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Annual Interest Rate (%, max-20)"
            value={rate}
            onChange={handleInputChange(setRate, inputLimits.rate)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Investment Period (years, max-30)"
            value={years}
            onChange={handleInputChange(setYears, inputLimits.years)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={calculateCompounding} fullWidth>
            Show Growth
          </Button>
        </Grid>
        {chartData && (
          <Grid item xs={12}>
            <Line data={chartData} />

            <Typography variant="body1" className="invest-content">
            
            Interpreting the Results:
            The results from the Power of Compounding Calculator show how your investment grows over time, factoring in the initial principal, the rate of return, and the number of compounding periods. The calculator helps you visualize the long-term benefits of staying invested and reinvesting your earnings. The key takeaway is that the longer your investment horizon, the greater the impact of compounding on your wealth. Even small, consistent contributions can grow significantly over time due to the exponential nature of compounding.
           </Typography>
            
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default PowerOfCompounding;
