import React, { useState } from 'react';


const ItemSubmissionForm = ({ pollType }) => {
  const [itemName, setItemName] = useState('');
  const [description, setDescription] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    if (itemName.trim() === '' || description.trim() === '') {
      setMessage('Item name and description are required.');
      return;
    }
      // Check if item name exceeds 5 words
      if (itemName.split(' ').length > 5) {
        setMessage('Item name cannot be more than 5 words.');
        return;
      }

    if (description.split(' ').length > 20) {
      setMessage('Description cannot be more than 20 words.');
      return;
    }

    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/submit-item`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ itemName, description, pollType }),
      credentials: 'include', // Include credentials for session authentication
    })
      .then(response => response.json().then(data => ({ data, status: response.status }))) // Capture both data and status
      .then(({ data, status }) => {
        if (data.message) {
          setMessage(data.message); // Display error or success message from backend
        }
        if (status === 200) {
          // Clear the form only on successful submission
          setItemName('');
          setDescription('');
        }
      })
      .catch(error => {
        console.error('Error submitting item:', error);
        setMessage('Failed to submit item. Login to submit');
      });
  };

  return (
    <div className="item-submission-form">
      <h3>Your preferred item not included in the Rankings? Submit below to include!</h3>
      {message && <p className="message">{message}</p>}

      {/* Submission form, always visible */}
      <form onSubmit={handleSubmit} className="submission-form">
        <input
          type="text"
          value={itemName}
          onChange={(e) => setItemName(e.target.value)}
          placeholder="Item Name"
          required
          className="item-name-input"
        />
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Item Description (max 20 words)"
          required
          className="description-input"
        />
        <button type="submit" className="submit-button">Submit</button>
      </form>

      {/* Small note to inform the user to log in */}
      <p className="login-note">Please <a href="/login">login</a> to submit information.</p>
    </div>
  );
};

export default ItemSubmissionForm;
