import React, { useState, useEffect } from "react";
import DynamicContent from '../DynamicContent'; 
import { Typography, Grid, Paper } from "@mui/material";

import '../PageStyle.css'; // Assuming you will style this with a CSS file

const LifeInsuranceCalculator = () => {
    const [annualIncome, setAnnualIncome] = useState(50000);
    const [age, setAge] = useState(30);
    const [retirementAge, setRetirementAge] = useState(65);
    const [currentDebt, setCurrentDebt] = useState(100000);
    const [futureNeeds, setFutureNeeds] = useState(200000);
    const [incomeReplacementYears, setIncomeReplacementYears] = useState(10);
  
    const yearsLeftToRetirement = retirementAge - age;
  
    // Human Life Value Method Calculation
    const hlvInsuranceNeeded = annualIncome * yearsLeftToRetirement;
  
    // Needs-based Method Calculation
    const needsBasedInsuranceNeeded =
      annualIncome * incomeReplacementYears + currentDebt + futureNeeds;
  
    // Real-time input validation for age and retirement age
    const handleIncomeChange = (e) => {
      const value = Number(e.target.value);
      if (value >= 0 && value <= 100000000) {
        setAnnualIncome(value);
      }
    };
  
    const handleAgeChange = (e) => {
      const value = Number(e.target.value);
      if (value >= 1 && value <= 100) {
        setAge(value);
      }
    };
  
    const handleRetirementAgeChange = (e) => {
      const value = Number(e.target.value);
      if (value >= 1 && value <= 100) {
        setRetirementAge(value);
      }
    };
  
    const handleDebtChange = (e) => {
      const value = Number(e.target.value);
      if (value >= 0 && value <= 100000000) {
        setCurrentDebt(value);
      }
    };
  
    const handleFutureNeedsChange = (e) => {
      const value = Number(e.target.value);
      if (value >= 0 && value <= 100000000) {
        setFutureNeeds(value);
      }
    };
  
    const handleIncomeReplacementYearsChange = (e) => {
      const value = Number(e.target.value);
      if (value >= 1 && value <= 40) {
        setIncomeReplacementYears(value);
      }
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      console.log('Form submitted');
    };

    const [content, setContent] = useState("");


   
    return (
      <div className="insurance-calculator-container">
         <h3>Life Insurance Needs Calculator</h3>

<Typography variant="body1" className="invest-content">
    <DynamicContent contentType='lifeinsurancecalculator' />
      </Typography>
       
  
        <form onSubmit={handleSubmit} className="calculator-form">
          <div className="form-section">
            <h4>Human Life Value (HLV) Method</h4>
  
            <label>
              Annual Income (₹):
              <input
                type="number"
                value={annualIncome}
                onChange={handleIncomeChange} // Restrict input to valid range
                className="input-field"
                min="0"
                max="100000000" // Limit set to ₹100,000,000
                required
              />
            </label>
  
            <label>
              Current Age:
              <input
                type="number"
                value={age}
                onChange={handleAgeChange} // Restrict input to valid range
                className="input-field"
                min="1"
                max="100" // Limit age between 18 and 100
                required
              />
            </label>
  
            <label>
              Retirement Age:
              <input
                type="number"
                value={retirementAge}
                onChange={handleRetirementAgeChange} // Restrict input to be greater than age
                className="input-field"
                min="1" // Ensure retirement age is greater than current age
                max="100" // Ensure the maximum is 100
                required
              />
            </label>
          </div>
  
          <div className="form-section">
            <h4>Needs-Based Method (DIME formula)</h4>
  
            <label>
              Current Debt (₹) (Debt + Mortgage):
              <input
                type="number"
                value={currentDebt}
                onChange={handleDebtChange} // Restrict input to valid range
                className="input-field"
                min="0"
                max="100000000" // Limit set to ₹100,000,000
                required
              />
            </label>
  
            <label>
              Future Financial Needs (Children Education and other needs) (₹):
              <input
                type="number"
                value={futureNeeds}
                onChange={handleFutureNeedsChange} // Restrict input to valid range
                className="input-field"
                min="0"
                max="100000000" // Limit set to ₹100,000,000
                required
              />
            </label>
  
            <label>
              Years of Income Replacement(Number of years your family would need your income):
              <input
                type="number"
                value={incomeReplacementYears}
                onChange={handleIncomeReplacementYearsChange} // Restrict input to valid range
                className="input-field"
                min="1"
                max="40" // Example limit for number of years for income replacement
                required
              />
            </label>
          </div>
  
          <button type="submit" className="submit-button">Calculate</button>
        </form>
  
        <div className="results">
          <h4>Results</h4>
          <p>
            <strong>HLV Method:</strong> You need approximately{' '}
            <strong>₹{hlvInsuranceNeeded.toLocaleString('en-IN')}</strong> in life
            insurance coverage based on your income and years to retirement.
          </p>
          <p>
            <strong>Needs-Based Method:</strong> You need approximately{' '}
            <strong>₹{needsBasedInsuranceNeeded.toLocaleString('en-IN')}</strong> in life
            insurance coverage based on your financial obligations and future
            needs.
          </p>
        </div>
      </div>
    );
  };
  
  export default LifeInsuranceCalculator;