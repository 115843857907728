import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Typography, Grid, Paper } from "@mui/material";
import { createClient } from "contentful";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'; // Import the rich text renderer
import "../PageStyle.css"; // Create and link a CSS file for styling

// Initialize Contentful client using environment variables
const client = createClient({
    space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
    accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
  });

function Save() {
const [content, setContent] = useState("");

useEffect(() => {
    // Replace '<your-contentful-entry-id>' with the actual entry ID from Contentful
    client
      .getEntry('29AEl374Sx692bfzD6y7LQ')
      .then((entry) => {
        console.log("Fetched entry:", entry); // Log the entire entry object to see all fields
        setContent(entry.fields.shortDescription);
      })
      .catch((error) => {
        console.error("Error fetching content:", error);
      });
  }, []);

  return (
    <div className="invest-page">
       <Typography variant="h3" gutterBottom>
        Save Money
      </Typography>
      <Typography variant="body1" className="invest-content">
        {content ? documentToReactComponents(content) : "Loading content..."}
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Paper className="invest-tool-card">
            <Typography variant="h5" gutterBottom>
              Expense Management Tool
            </Typography>
            <img src="/images/expensetool.jpg" alt="Expense Management Tool" className="tool-image" />
            <Typography variant="body2" gutterBottom>
              Bring discipline in your discretionay expenses using this tool
            </Typography>
            <Link to="/savingpage" className="invest-tool-link">
              Try Now
            </Link>
          </Paper>
        </Grid>
       
        {/* Add more calculators/tools in a similar way */}

        {/* More tools can be added here */}
      </Grid>
    </div>
  );
}

export default Save;
