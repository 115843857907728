import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import PropTypes from "prop-types";
import { styled } from "@mui/system";
import { log } from '../utils/log';

const StyledTableHead = styled(TableHead)`
  background-color: #ffb900; // Use the yellow color from the provided image
`;

const StyledTableCell = styled(TableCell)`
  color: white;
  font-weight: bold;
`;

const StyledTableBodyCell = styled(TableCell)`
  color: #333; // Adjust the text color as needed
`;

const DialogBox = ({
  open,
  handleClose,
  rankInfo,
  form = {},
  alreadySubmittedMessage,
}) => {
  const formatValue = (value) => {
    if (value == null || isNaN(value)) {
      return "0.00";
    }
    return parseFloat(value).toFixed(2);
  };
  log("DialogBox rankInfo:", rankInfo);
  log("DialogBox alreadySubmittedMessage:", alreadySubmittedMessage);
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Your Networth Ranking</DialogTitle>

      <DialogContent>
        {alreadySubmittedMessage && (
          <Typography color="error" gutterBottom>
            {alreadySubmittedMessage}
          </Typography>
        )}
        {rankInfo ? (
          <>
            <Typography variant="body1">
              Your Net Worth: INR {formatValue(rankInfo.netWorth)} Lakhs
            </Typography>
            <Typography variant="body1">
              Overall Rank: {rankInfo.overallRank}
            </Typography>
            <Typography variant="body1">
              Rank in {rankInfo.city || "N/A"}: {rankInfo.cityRank}
            </Typography>
            <Typography variant="body1">
              Rank in Age Group ({rankInfo.age || "N/A"}): {rankInfo.ageRank}
            </Typography>
            <Typography variant="body1">
              Rank in {rankInfo.country || "N/A"}: {rankInfo.countryRank}
            </Typography>
            <Typography variant="h6" gutterBottom>
              Asset Distribution:
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <StyledTableHead>
                  <TableRow>
                    <StyledTableCell>Asset Type</StyledTableCell>
                    <StyledTableCell>Your Asset Allocation (%)</StyledTableCell>
                    <StyledTableCell>
                      Average Allocation in {rankInfo.country || "N/A"} (%)
                    </StyledTableCell>
                  </TableRow>
                </StyledTableHead>
                <TableBody>
                  <TableRow>
                    <StyledTableBodyCell>Property</StyledTableBodyCell>
                    <StyledTableBodyCell>
                      {rankInfo.userPercentages
                        ? formatValue(rankInfo.userPercentages.property)
                        : "N/A"}
                    </StyledTableBodyCell>
                    <StyledTableBodyCell>
                      {rankInfo.countryAvgPercentages
                        ? formatValue(
                            rankInfo.countryAvgPercentages.avg_property
                          )
                        : "N/A"}
                    </StyledTableBodyCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableBodyCell>Vehicles</StyledTableBodyCell>
                    <StyledTableBodyCell>
                      {rankInfo.userPercentages
                        ? formatValue(rankInfo.userPercentages.vehicles)
                        : "N/A"}
                    </StyledTableBodyCell>
                    <StyledTableBodyCell>
                      {rankInfo.countryAvgPercentages
                        ? formatValue(
                            rankInfo.countryAvgPercentages.avg_vehicles
                          )
                        : "N/A"}
                    </StyledTableBodyCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableBodyCell>Stocks</StyledTableBodyCell>
                    <StyledTableBodyCell>
                      {rankInfo.userPercentages
                        ? formatValue(rankInfo.userPercentages.stocks)
                        : "N/A"}
                    </StyledTableBodyCell>
                    <StyledTableBodyCell>
                      {rankInfo.countryAvgPercentages
                        ? formatValue(rankInfo.countryAvgPercentages.avg_stocks)
                        : "N/A"}
                    </StyledTableBodyCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableBodyCell>Cash</StyledTableBodyCell>
                    <StyledTableBodyCell>
                      {rankInfo.userPercentages
                        ? formatValue(rankInfo.userPercentages.cash)
                        : "N/A"}
                    </StyledTableBodyCell>
                    <StyledTableBodyCell>
                      {rankInfo.countryAvgPercentages
                        ? formatValue(rankInfo.countryAvgPercentages.avg_cash)
                        : "N/A"}
                    </StyledTableBodyCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableBodyCell>Gold</StyledTableBodyCell>
                    <StyledTableBodyCell>
                      {rankInfo.userPercentages
                        ? formatValue(rankInfo.userPercentages.gold)
                        : "N/A"}
                    </StyledTableBodyCell>
                    <StyledTableBodyCell>
                      {rankInfo.countryAvgPercentages
                        ? formatValue(rankInfo.countryAvgPercentages.avg_gold)
                        : "N/A"}
                    </StyledTableBodyCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <Typography variant="body1">
          You need to log in to submit your information.
        </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
DialogBox.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  rankInfo: PropTypes.object,
  form: PropTypes.object, // Make this optional to avoid warnings
  alreadySubmittedMessage: PropTypes.string,
};

export default DialogBox;
