import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom'; // To get poll_type from URL
import { Link } from "react-router-dom";
import { Typography, Grid, Paper } from "@mui/material";
import DynamicContent from '../DynamicContent'; 
import ShareButtons from "../../utils/ShareButtons"; 
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'; // Import the rich text renderer

import "../PageStyle.css"; // Create and link a CSS file for styling



function Grow() {
  const { contentType } = useParams(); // Capture the content type from the URL
console.log('Content type', contentType);

const baseUrl = window.location.origin;

  return (
    <div className="invest-page">
       <Typography variant="h3" gutterBottom>
       Boost Your Income: Take Control of Your Financial Future
      </Typography>
      <Typography variant="body1" className="invest-content">
      <DynamicContent contentType={contentType || 'grow'} /> {/* Pass contentType to DynamicContent */}
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Paper className="invest-tool-card" style={{ position: "relative" }}>
            <Typography variant="h5" gutterBottom>
              Highest Paid CEOs in India
            </Typography>
            <img src="/FRlogo.png" className="tool-image" />
            <Typography variant="body2" gutterBottom>
              How do the top companies in India reward thier CEOs?
            </Typography>
            <Link to="/IndianCEOcompensation" className="invest-tool-link">
              Try Now
            </Link>
            <ShareButtons link={`${baseUrl}/lifeinsurancecalculator`} />
          </Paper>
        </Grid>


        {/* More tools can be added here */}
      </Grid>
    </div>
  );
}

export default Grow;
