import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Typography, Grid, Paper } from "@mui/material";
import DynamicContent from '../DynamicContent'; 
import ShareButtons from "../../utils/ShareButtons"; 
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'; // Import the rich text renderer

import "../PageStyle.css"; // Create and link a CSS file for styling



function Protect() {

const baseUrl = window.location.origin;

  return (
    <div className="invest-page">
       <Typography variant="h3" gutterBottom>
        Protect you wealth
      </Typography>
      <Typography variant="body1" className="invest-content">
      <DynamicContent contentType='protect' /> {/* Pass contentType to DynamicContent */}
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <Paper className="invest-tool-card" style={{ position: "relative" }}>
            <Typography variant="h5" gutterBottom>
              Life Insurance Calculator
            </Typography>
            <img src="/powerofcompounding.jpg" alt="Compounding Tool" className="tool-image" />
            <Typography variant="body2" gutterBottom>
              Understand how much life insurance you need
            </Typography>
            <Link to="/lifeinsurancecalculator" className="invest-tool-link">
              Try Now
            </Link>
            <ShareButtons link={`${baseUrl}/lifeinsurancecalculator`} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper className="invest-tool-card" style={{ position: "relative" }}>
          <Typography variant="h5" gutterBottom>
              Life Insurance Cover Poll
            </Typography>
            <img src="/powerofcompounding.jpg" alt="Compounding Tool" className="tool-image" />
            <Typography variant="body2" gutterBottom>
              See how your life Insurance cover compares with others
            </Typography>
            <Link to="/polls/LifeInsuranceCoverage" className="invest-tool-link">
              Try Now
            </Link>
            <ShareButtons link={`${baseUrl}/polls/LifeInsuranceCoverage`} />
          </Paper>
        </Grid>

        {/* More tools can be added here */}
      </Grid>
    </div>
  );
}

export default Protect;
