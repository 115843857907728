import React, { useEffect, useState } from "react";
import { Slider, Typography, Grid, TextField } from "@mui/material";
import { styled } from "@mui/system";

const StyledSlider = styled(Slider)`
  color: #ffb900;
  & .MuiSlider-thumb {
    background-color: #ffb900;
    width: ${({ isMobile }) => (isMobile ? "24px" : "16px")};  // Increase thumb size for mobile
    height: ${({ isMobile }) => (isMobile ? "24px" : "16px")};
    &:hover,
    &.Mui-focusVisible,
    &.Mui-active {
      box-shadow: 0px 0px 0px 8px rgba(255, 185, 0, 0.16);
    }
  }
`;

const AssetSlider = ({
  label,
  value,
  onChange,
  maxValue,
  step1,
  step2,
  step3,
  breakpoint1,
  breakpoint2,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const totalSteps1 = Math.ceil(breakpoint1 / step1);
  const totalSteps2 = Math.ceil((breakpoint2 - breakpoint1) / step2);
  const totalSteps3 = Math.ceil((maxValue - breakpoint2) / step3);

  const adjustedMaxValue = totalSteps1 + totalSteps2 + totalSteps3;

  const marks = [
    { value: 0 },
    { value: totalSteps1 },
    { value: totalSteps1 + totalSteps2 },
    { value: adjustedMaxValue },
  ];

  const handleChange = (event, newValue) => {
    let adjustedValue;
    if (newValue <= totalSteps1) {
      adjustedValue = newValue * step1;
    } else if (newValue <= totalSteps1 + totalSteps2) {
      adjustedValue = breakpoint1 + (newValue - totalSteps1) * step2;
    } else {
      adjustedValue =
        breakpoint2 + (newValue - totalSteps1 - totalSteps2) * step3;
    }
    onChange(event, adjustedValue);
  };

  const handleTextFieldChange = (e) => {
    let inputValue = parseFloat(e.target.value);
    if (isNaN(inputValue)) {
      inputValue = 0;
    } else if (inputValue > maxValue) {
      inputValue = maxValue;  // Restrict value to maxValue
    }
    onChange(null, inputValue);
  };

  const handleMouseDown = (event) => {
    if (isMobile) {
      event.preventDefault();
    }
    setIsDragging(true);
  };

  const handleTouchStart = (event) => {
    setIsDragging(true);
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={9}>
        <Typography gutterBottom>{label} (₹ lakhs)</Typography>
        <StyledSlider
          isMobile={isMobile}  // Pass mobile state to StyledSlider
          value={
            value <= breakpoint1
              ? value / step1
              : value <= breakpoint2
              ? totalSteps1 + (value - breakpoint1) / step2
              : totalSteps1 + totalSteps2 + (value - breakpoint2) / step3
          }
          onChange={handleChange}
          onMouseDown={handleMouseDown}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          step={1}
          marks={marks}
          min={0}
          max={adjustedMaxValue}
          valueLabelDisplay="auto"
          valueLabelFormat={(val) => {
            if (val <= totalSteps1) {
              return (val * step1).toFixed(2);
            } else if (val <= totalSteps1 + totalSteps2) {
              return (breakpoint1 + (val - totalSteps1) * step2).toFixed(2);
            } else {
              return (
                breakpoint2 +
                (val - totalSteps1 - totalSteps2) * step3
              ).toFixed(2);
            }
          }}
          sx={{ width: "100%" }}
        />
      </Grid>
      <Grid item xs={3}>
        {/* Add numerical input with max value restriction and smaller size */}
        <TextField
          label={`${label} (₹ lakhs)`}
          value={value}
          onChange={handleTextFieldChange}
          type="number"
          fullWidth
          size="small" // Make the TextField smaller
          inputProps={{ step: "0.1", min: 0, max: maxValue }}  // Define max value in input props
        />
      </Grid>
    </Grid>
  );
};

export default AssetSlider;
