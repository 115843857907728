import React, { useState, useEffect } from 'react';
import axios from 'axios';
import usePageTracking from '../hooks/usePageTracking';

const AdminDashboard = () => {
    const [dashboardData, setDashboardData] = useState(null);
    usePageTracking(); // Track page views

  
    useEffect(() => {
        axios.get('/api/admin-dashboard')
          .then(response => setDashboardData(response.data))
          .catch(err => console.error('Error fetching admin dashboard data', err));
      }, []);
    
      if (!dashboardData) return <div>Loading...</div>;
    
      return (
        <div className="admin-dashboard-container">
          <h2>Admin Dashboard</h2>
          <table className="admin-dashboard-table">
            <thead>
              <tr>
                <th>Table Name</th>
                <th>Total Count (Most Recent)</th>
                <th>Total Count (Previous Day)</th>
                <th>Latest Entry Time</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(dashboardData).map(([tableName, tableData]) => (
                <tr key={tableName}>
                  <td>{tableName}</td>
                  <td>{tableData.total_count}</td>
                  <td>{tableData.previous_day_count}</td> {/* Show previous day's count */}
                  <td>{tableData.latest_entry_time}</td> {/* Display N/A if created_at is not available */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    };
    
    export default AdminDashboard;