import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext'; // Make sure this path is correct
import './Comments.css'; // Assuming this file exists for styling

const Comments = ({ pollType }) => {
  const { userInfo } = useAuth(); // Check if user is authenticated
  const [comments, setComments] = useState([]);
  const [commentText, setCommentText] = useState('');
  const [replyingTo, setReplyingTo] = useState(null); // Track which comment is being replied to
  const [errorMessage, setErrorMessage] = useState('');

  // Fetch comments for the specific poll_type
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/comments/comments?pollType=${pollType}`)
      .then(response => response.json())
      .then(data => {
        console.log('Fetched comments:', data); // Log the fetched comments
        if (Array.isArray(data)) {
          setComments(data);
        } else {
          setComments([]);
        }
      })
      .catch(error => console.error('Error:', error));
  }, [pollType]);

  const handleCommentSubmit = (e) => {
    e.preventDefault();
  
    // Check if comment text is empty
    if (commentText.trim() === '') return;
  
    // Word count validation (50 words max)
    const wordCount = commentText.split(/\s+/).length;
    if (wordCount > 50) {
      alert('Comment cannot be more than 50 words.');
      return;
    }
  
    // Submit the comment via fetch API
    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/comments/comments`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ pollType, commentText, parentCommentId: replyingTo }),
      credentials: 'include', // Important to send cookies (session ID)
    })
      .then((response) => {
        if (!response.ok) {
          // If status is 401 Unauthorized, show a login message
          if (response.status === 401) {
            throw new Error('Please login to add a comment.');
          }
  
          // For other errors, throw the backend's message
          return response.json().then((data) => {
            throw new Error(data.message || 'Failed to submit comment.');
          });
        }
        return response.json();
      })
      .then((newComment) => {
        console.log('New Comment from Backend:', newComment);
        setComments((prevComments) => [...prevComments, newComment]);
        setCommentText('');
        setReplyingTo(null); // Reset the reply state after submitting
        setErrorMessage(''); // Clear any existing error message
      })
      .catch((error) => {
        console.error('Error:', error);
        setErrorMessage(error.message); // Display the error message to the user
       
      });
  };

  const handleReplyClick = (commentId) => {
    setReplyingTo(commentId); // Set the comment being replied to
  };

  const handleVote = (commentId, voteType) => {
    fetch(`${process.env.REACT_APP_API_BASE_URL}/api/comments/vote`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ commentId, voteType }),
      credentials: 'include' // Important to send cookies (session ID)
    })
    .then(response => response.json())
    .then(updatedComment => {
      // Update the state with the updated comment (including votes and first_name)
      setComments(prevComments => 
        prevComments.map(comment => 
          comment.id === updatedComment.id ? updatedComment : comment
        )
      );
    })
    .catch(error => console.error('Error voting on comment:', error));
};

  return (
    <div className="comments-section">
      <h3>Comments for {pollType}</h3>

      {errorMessage && <p className="error-message">{errorMessage}</p>} {/* Display error message if exists */}

      <form onSubmit={handleCommentSubmit}>
        <textarea
          value={commentText}
          onChange={(e) => setCommentText(e.target.value)}
          placeholder={replyingTo ? "Reply to comment..." : "Add a comment"}
          required
        />
        <button type="submit">{replyingTo ? "Reply" : "Submit"}</button>
        {replyingTo && (
          <button type="button" onClick={() => setReplyingTo(null)}>
            Cancel Reply
          </button>
        )}
      </form>

      <ul className="comments-list">
  {comments
    .filter(comment => comment.parent_comment_id === null) // Show only top-level comments initially
    .map(comment => (
      <li key={comment.id} className="comment">
        <strong>{comment.first_name}</strong>: {comment.comment}
        <div className="comment-votes">
          <button onClick={() => handleVote(comment.id, 'upvote')}>
            <svg viewBox="0 0 24 24">
              <path d="M12 2l6 12H6z" />
            </svg>
          </button>
          <span className="votes-count">{comment.upvotes}</span>
          <button onClick={() => handleVote(comment.id, 'downvote')}>
            <svg viewBox="0 0 24 24">
              <path d="M12 22l-6-12h12z" />
            </svg>
          </button>
          <span className="votes-count">{comment.downvotes}</span>
          <button onClick={() => handleReplyClick(comment.id)}>Reply</button> {/* Reply button */}
        </div>

        {/* Nested replies */}
        <ul className="replies-list">
          {comments
            .filter(reply => reply.parent_comment_id === comment.id) // Display replies for this comment
            .map(reply => (
              <li key={reply.id} className="reply">
                <strong>{reply.first_name}</strong>: {reply.comment}
                <div className="comment-votes">
                  <button onClick={() => handleVote(reply.id, 'upvote')}>
                    <svg viewBox="0 0 24 24">
                      <path d="M12 2l6 12H6z" />
                    </svg>
                  </button>
                  <span className="votes-count">{reply.upvotes}</span>
                  <button onClick={() => handleVote(reply.id, 'downvote')}>
                    <svg viewBox="0 0 24 24">
                      <path d="M12 22l-6-12h12z" />
                    </svg>
                  </button>
                  <span className="votes-count">{reply.downvotes}</span>
                </div>
              </li>
            ))}
        </ul>
      </li>
    ))}
</ul>
    </div>
  );
};

export default Comments;
