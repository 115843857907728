import React, { useState, useEffect } from "react";
import { TextField, Button, Grid, Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { createClient } from "contentful";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'; // Import the rich text renderer
const BuyVsRent = () => {
  const [inputs, setInputs] = useState({
    startingAssetValue: 100,
    loanTaken: 90,
    rentalYield: 3,
    maintenanceCost: 0,
    interestRate: 8,
    loanTenure: 15,
    assetPriceIncrease: 7,
    investmentReturn: 12,
  });



  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs({ ...inputs, [name]: parseFloat(value) });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const calculationResults = BuyVsRentCalculator(inputs);
    setResults(calculationResults);
  };

  const upfrontAmount = inputs.startingAssetValue - inputs.loanTaken;
  const rentAmount = ((inputs.rentalYield/100) * (inputs.startingAssetValue*100000))/12;

  // Initialize Contentful client using environment variables
  const client = createClient({
    space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
    accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
  });
  
  
    const [results, setResults] = useState(null);

  const [content, setContent] = useState("");
  useEffect(() => {
    // Replace '<your-contentful-entry-id>' with the actual entry ID from Contentful
    client
      .getEntry('4o6DjHl0dGLXBR9w4ryIZA')
      .then((entry) => {
        console.log("Fetched entry:", entry); // Log the entire entry object to see all fields
        setContent(entry.fields.shortDescription);
      })
      .catch((error) => {
        console.error("Error fetching content:", error);
      });
  }, []);
  return (
    
    <Box sx={{ maxWidth: 800, mx: 'auto', p: 3 }}>
      <Box component="form" onSubmit={handleSubmit} sx={{ mb: 4 }}>

        <Typography variant="h4" gutterBottom>
          Buy vs. Rent Analysis
        </Typography>
        
      <Typography variant="body1" className="invest-content">
        {content ? documentToReactComponents(content) : "Loading content..."}
      </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Starting Asset Value (in ₹ Lakhs)"
              name="startingAssetValue"
              value={inputs.startingAssetValue}
              onChange={handleChange}
              type="number"
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Amount of Loan Taken (in ₹ Lakhs)"
              name="loanTaken"
              value={inputs.loanTaken}
              onChange={handleChange}
              type="number"
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Upfront Amount"
              value={upfrontAmount}
              disabled
            />
          </Grid>
         
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Interest Rate (%)"
              name="interestRate"
              value={inputs.interestRate}
              onChange={handleChange}
              type="number"
              required
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Rental Yield (%)"
              name="rentalYield"
              value={inputs.rentalYield}
              onChange={handleChange}
              type="number"
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Rent Paid/Saved per Month (₹)"
              value={rentAmount}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Annual Maintenance Cost (%)"
              name="maintenanceCost"
              value={inputs.maintenanceCost}
              onChange={handleChange}
              type="number"
              required
            />
          </Grid>
 
          <Grid item xs={12} sm={6}>
           <TextField
              fullWidth
              label="Loan Tenure (years, Max 30  years)"
              name="loanTenure"
              value={inputs.loanTenure}
              onChange={handleChange}
              type="number"
              required
              inputProps={{ max: 30 }} // This restricts the input to a maximum of 30 years
            />
        </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Annual Increase in Asset Price (%)"
              name="assetPriceIncrease"
              value={inputs.assetPriceIncrease}
              onChange={handleChange}
              type="number"
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Annualized Return on Investment (%)"
              name="investmentReturn"
              value={inputs.investmentReturn}
              onChange={handleChange}
              type="number"
              required
            />
          </Grid>
        </Grid>
        <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
          Calculate
        </Button>
      </Box>

      {results && (
        <Box sx={{ mt: 4 }}>
          <Typography variant="h5" gutterBottom>
            Buy vs. Rent Analysis Results (₹ Lakhs)
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Year</TableCell>
                  <TableCell>Rent Saved/Paid (A)</TableCell>
                  <TableCell>EMI (B)</TableCell>
                  <TableCell>Net Cashflow (C=A-B-maintenance cost)</TableCell>
                  <TableCell>Future Value of Net Cashflow Invested (D)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {results.netCashflows.map((cashflow, index) => (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{`₹ ${results.rentSaved[index].toFixed(1)}`}</TableCell>
                    <TableCell>{`₹ ${results.emi.toFixed(1)}`}</TableCell>
                    <TableCell>{`₹ ${-cashflow.toFixed(1)}`}</TableCell>
                    <TableCell>{`₹ ${-results.netCashflowsInvested[index].toFixed(1)}`}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ mt: 3 }}>
  <Grid container spacing={2}>
    <Grid item xs={6}>
      <Box sx={{ border: '1px solid #ccc', padding: 2, borderRadius: '4px', backgroundColor: '#f5f5f5' }}>
        <Typography variant="h6" gutterBottom>
          Buying
        </Typography>
        <Typography variant="body1">
          Total investment for buying (upfront amount +sum of (C)): ₹ {results.totalInvestmentForBuying.toFixed(1)}
        </Typography>
        <Typography variant="body1">
          Asset value after Loan tenure: ₹ {results.assetValueAfterLoanTenure.toFixed(1)}
        </Typography>
        <Typography variant="body1">
          Gain from Buying: ₹ {results.gainFromBuying.toFixed(1)}
        </Typography>
      </Box>
    </Grid>
    <Grid item xs={6}>
      <Box sx={{ border: '1px solid #ccc', padding: 2, borderRadius: '4px', backgroundColor: '#f5f5f5' }}>
        <Typography variant="h6" gutterBottom>
          Renting
        </Typography>
        <Typography variant="body1">
          Total investment post renting (upfront amount +sum of (C)): ₹ {results.totalInvestmentForBuying.toFixed(1)}
        </Typography>
        <Typography variant="body1">
          Total Investment value after loan tenure (sum of (D)+future value of upfront amount invested): ₹ {results.totalInvestmentValueAfterRenting.toFixed(1)}
        </Typography>
        <Typography variant="body1">
          Gain from Renting: ₹ {results.gainFromRenting.toFixed(1)}
        </Typography>
      </Box>
    </Grid>
  </Grid>
</Box>
        </Box>
      )}
    </Box>
  );
};

const BuyVsRentCalculator = (inputs) => {
  const {
    startingAssetValue,
    loanTaken,
    rentalYield,
    maintenanceCost,
    interestRate,
    loanTenure,
    assetPriceIncrease,
    investmentReturn,
  } = inputs;

  const upfrontAmount = startingAssetValue - loanTaken;
  const emi = loanTaken * (interestRate / 100) * Math.pow(1 + (interestRate / 100), loanTenure) / 
              (Math.pow(1 + (interestRate / 100), loanTenure) - 1);

  let netCashflows = [];
  let netCashflowsInvested = [];
  let rentSavedArray = [];
  let assetValue = startingAssetValue;
  let totalInvestmentForBuying = upfrontAmount;

  for (let year = 1; year <= loanTenure; year++) {
    const rentSaved = rentalYield / 100 * assetValue;
    const netCashflow = rentSaved - emi - (maintenanceCost / 100 * assetValue);
    netCashflows.push(netCashflow);
    rentSavedArray.push(rentSaved);
    const investedValue = netCashflow * Math.pow(1 + investmentReturn / 100, loanTenure - year);
    netCashflowsInvested.push(investedValue);

    assetValue *= (1 + assetPriceIncrease / 100);
    totalInvestmentForBuying += emi-rentSaved;
  }

  const gainFromBuying = assetValue - totalInvestmentForBuying;
  const totalInvestmentValueAfterRenting = -netCashflowsInvested.reduce((acc, val) => acc + val, 0) + upfrontAmount * Math.pow((1 + investmentReturn / 100), loanTenure);


  return {
    emi,
    netCashflows,
    netCashflowsInvested,
    rentSaved: rentSavedArray,
    assetValueAfterLoanTenure: assetValue,
    totalInvestmentForBuying,
    gainFromBuying,
    totalInvestmentValueAfterRenting,
    gainFromRenting: totalInvestmentValueAfterRenting - totalInvestmentForBuying,
  };
};

export default BuyVsRent;
