import { useEffect } from 'react';

const usePageTracking = () => {
  useEffect(() => {
    if (window.gtag) {
      window.gtag('config', 'YOUR_TRACKING_ID', {
        page_path: window.location.pathname,
      });
    }
  }, []);
};

export default usePageTracking;
