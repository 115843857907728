// src/components/DynamicContent.js
import React, { useState, useEffect } from 'react';
import { fetchContent } from '../utils/contentfulClient'; // Import the function to fetch content from Contentful
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'; // Use to render rich text content

const DynamicContent = ({ contentType }) => {
  const [content, setContent] = useState(null);
  const [columnHeadings, setColumnHeadings] = useState([]); // For table headings
  const [rankingData, setRankingData] = useState([]); // For table data (ranking)
  const [contentTypeFetched, setContentTypeFetched] = useState(''); // Track the type of content fetched
  const [error, setError] = useState(null); // Error handling

  useEffect(() => {
    if (contentType) {
      const loadContent = async () => {
        try {
          const fetchedContent = await fetchContent(contentType); // Fetch content based on the URL

          if (fetchedContent.body) {
            setContent(fetchedContent.body);
          }

          if (fetchedContent.columnHeadings) {
            setColumnHeadings(fetchedContent.columnHeadings);
          }

          if (fetchedContent.rankingData) {
            setRankingData(fetchedContent.rankingData);
          }

          setContentTypeFetched(fetchedContent.type); // Store the type of content fetched
        } catch (err) {
          setError('Failed to load content.');
          console.error('Error fetching content:', err);
        }
      };

      loadContent();
    }
  }, [contentType]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      {/* Remove the h1 that displayed contentType */}
      
      {/* Render the article body if available */}
      {content && (
        <div>
          {documentToReactComponents(content)}
        </div>
      )}

      {/* Conditionally render the ranking table if content type is ArticleWithRankings and ranking data is available */}
      {contentTypeFetched === 'ArticleWithRankings' && rankingData.length > 0 && (
        <div>
          <h2>Ranking Table</h2>
          <table>
            <thead>
              <tr>
                {columnHeadings.map((heading, index) => (
                  <th key={index}>{heading}</th> // Render column headings dynamically
                ))}
              </tr>
            </thead>
            <tbody>
              {rankingData.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {Object.values(row).map((value, colIndex) => (
                    <td key={colIndex}>{value}</td> // Render the table data dynamically
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* If no rankings are available, show a message or additional content */}
      {contentTypeFetched === 'ArticleWithRankings' && rankingData.length === 0 && (
        <p>No rankings available for this article.</p>
      )}
    </div>
  );
};

export default DynamicContent;
