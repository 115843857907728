import React, { useState } from "react";
import axios from "axios";
import "../styles.css";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(""); // Correctly add useState for error

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/auth/forgot-password`, { email });
      setMessage(response.data.message);
      setError(""); // Clear any previous errors
    } catch (err) {
      console.error("Forgot password error", err);
      if (err.response && err.response.status === 404) {
        setError("No user found with this email address.");
      } else {
        setError("Failed to send password reset email.");
      }
      setMessage(""); // Clear any previous messages
    }
  };

  return (
    <div className="auth-container">
      <h2>Forgot Password</h2>
      <form className="auth-form" onSubmit={handleForgotPassword}>
        <div>
          <label>Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="auth-button">
          Send Reset Link
        </button>
      </form>
      {message && <p>{message}</p>}
      {error && <p className="error">{error}</p>} 
    </div>
  );
};

export default ForgotPassword;
