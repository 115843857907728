import FingerprintJS from "@fingerprintjs/fingerprintjs";
import CryptoJS from "crypto-js";

// Function to fetch the user's IP address
const fetchIp = async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/ip`);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json(); // Parse the response as JSON
    return data.ip; // Access the `ip` field from the parsed data
  } catch (error) {
    console.error('Error fetching IP:', error);
    return null;
  }
};

// Function to generate a hashed unique ID
export const generateHashedUniqueId = async () => {
  let ip = await fetchIp();
  if (!ip) return null; // If no IP, return null

  // Handle IPv6 localhost address
  if (ip === `::1`) {
    ip = `127.0.0.1`;
  }

  const ipParts = ip.split('.').slice(0, 3).join('.');
  const fp = await FingerprintJS.load();
  const result = await fp.get();
  const fingerprint = result.visitorId;

  // Combine IP parts and fingerprint, and hash them
  const uniqueId = `${ipParts}-${fingerprint}`;
  const hash = CryptoJS.SHA256(uniqueId).toString();

  return hash;
};
